"use strict";
var WebTour = function () {
  'use strict';

  function e(e, t) {
    if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function")
  }

  function t(e, t) {
    for (var o, n = 0; n < t.length; n++) o = t[n], o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o)
  }

  function o(e, o, n) {
    return o && t(e.prototype, o), n && t(e, n), e
  }

  function n(e, t, o) {
    return t in e ? Object.defineProperty(e, t, {
      value: o,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = o, e
  }

  function s(e, t) {
    var o = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
      var n = Object.getOwnPropertySymbols(e);
      t && (n = n.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable
      })), o.push.apply(o, n)
    }
    return o
  }

  function i(e) {
    for (var t, o = 1; o < arguments.length; o++) t = null == arguments[o] ? {} : arguments[o], o % 2 ? s(Object(t), !0).forEach(function (o) {
      n(e, o, t[o])
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : s(Object(t)).forEach(function (o) {
      Object.defineProperty(e, o, Object.getOwnPropertyDescriptor(t, o))
    });
    return e
  }

  function l(e, t) {
    return d(e) || r(e, t) || a(e, t) || f()
  }

  function d(e) {
    if (Array.isArray(e)) return e
  }

  function r(e, t) {
    if ("undefined" != typeof Symbol && Symbol.iterator in Object(e)) {
      var o = [], n = !0, s = !1, i = void 0;
      try {
        for (var l, d = e[Symbol.iterator](); !(n = (l = d.next()).done) && (o.push(l.value), !(t && o.length === t)); n = !0) ;
      } catch (e) {
        s = !0, i = e
      } finally {
        try {
          n || null == d["return"] || d["return"]()
        } finally {
          if (s) throw i
        }
      }
      return o
    }
  }

  function a(e, t) {
    if (e) {
      if ("string" == typeof e) return p(e, t);
      var o = Object.prototype.toString.call(e).slice(8, -1);
      return "Object" === o && e.constructor && (o = e.constructor.name), "Map" === o || "Set" === o ? Array.from(e) : "Arguments" === o || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(o) ? p(e, t) : void 0
    }
  }

  function p(e, t) {
    (null == t || t > e.length) && (t = e.length);
    for (var o = 0, n = Array(t); o < t; o++) n[o] = e[o];
    return n
  }

  function f() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
  }

  var h = function () {
    var n = Math.abs;

    function t() {
      var o = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : {};
      return (e(this, t), !!this.constructor.instance) ? this.constructor.instance : (this.constructor.instance = this, this.options = i({
        animate: !0,
        opacity: .5,
        offset: 20,
        borderRadius: 3,
        allowClose: !0,
        highlight: !0,
        highlightOffset: 5,
        keyboard: !0,
        width: "300px",
        zIndex: 10050,
        removeArrow: !1,
        onNext: function () {
          return null
        },
        onPrevious: function () {
          return null
        }
      }, o), this.steps = [], this.stepIndex = 0, this.isRunning = !1, this.isPaused = !1, this.window = window, this.document = document, this.onClick = this.onClick.bind(this), this.onResize = this.onResize.bind(this), this.onKeyUp = this.onKeyUp.bind(this), this.bind(), this)
    }

    return o(t, [{
      key: "bind", value: function () {
        "ontouchstart" in this.document.documentElement ? this.window.addEventListener("touchstart", this.onClick, !1) : this.window.addEventListener("click", this.onClick, !1), this.window.addEventListener("resize", this.onResize, !1), this.window.addEventListener("keyup", this.onKeyUp, !1)
      }
    }, {
      key: "onClick", value: function (t) {
        t.stopPropagation(), t.target.classList.contains("wt-btn-next") && (this.onNext(), this.next()), t.target.classList.contains("wt-btn-back") && (this.onPrevious(), this.previous()), t.target.classList.contains("wt-overlay") && this.options.allowClose && this.stop()
      }
    }, {
      key: "onKeyUp", value: function (e) {
        return this.isRunning && this.options.keyboard ? 27 === e.keyCode && this.options.allowClose ? void this.stop() : void (39 === e.keyCode ? (this.onNext(), this.next()) : 37 === e.keyCode && (this.onPrevious(), this.previous())) : void 0
      }
    }, {
      key: "onResize", value: function () {
        this.isRunning && (this.clear(), this.render(this.steps[this.stepIndex]))
      }
    }, {
      key: "setSteps", value: function (e) {
        this.steps = null, this.steps = e
      }
    }, {
      key: "getSteps", value: function () {
        return this.steps
      }
    }, {
      key: "highlight", value: function (e) {
        var t = this, o = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : null;
        return function (e) {
          t.isRunning = !0;
          var e = t.document.querySelector(e);
          e && (o ? (t.steps = null, t.stepIndex = 0, t.steps = o, t.render(t.steps[t.stepIndex])) : t.createOverlay(e, o))
        }(e)
      }
    }, {
      key: "start", value: function () {
        var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : 0;
        this.isRunning = !0, this.stepIndex = e, this.render(this.steps[this.stepIndex])
      }
    }, {
      key: "stop", value: function () {
        this.clear(), this.isRunning = !1
      }
    }, {
      key: "showLoader", value: function () {
        var e = this.document.querySelector(".wt-popover"), t = this.document.createElement("div");
        t.classList.add("wt-loader"), t.style.zIndex = this.options.zIndex + 10, e.prepend(t)
      }
    }, {
      key: "moveNext", value: function () {
        this.isPaused = !1, this.next()
      }
    }, {
      key: "movePrevious", value: function () {
        this.isPaused = !1, this.previous()
      }
    }, {
      key: "onNext", value: function () {
        this.isPaused || this.steps[this.stepIndex] && this.steps[this.stepIndex].onNext && this.steps[this.stepIndex].onNext()
      }
    }, {
      key: "onPrevious", value: function () {
        this.isPaused || this.steps[this.stepIndex] && this.steps[this.stepIndex].onPrevious && this.steps[this.stepIndex].onPrevious()
      }
    }, {
      key: "next", value: function () {
        if (!this.isPaused) return this.stepIndex++, this.clear(), 0 !== this.steps.length && (this.stepIndex >= this.steps.length ? void this.stop() : void this.render(this.steps[this.stepIndex]))
      }
    }, {
      key: "previous", value: function () {
        if (!this.isPaused) return this.stepIndex--, this.clear(), 0 !== this.steps.length && (0 > this.stepIndex ? void this.stop() : void this.render(this.steps[this.stepIndex]))
      }
    }, {
      key: "render", value: function (e) {
        var t = e.element ? this.document.querySelector(e.element) : null;
        if (t) {
          t.style.position = t.style.position ? t.style.position : "relative";
          var o = !e.highlight || e.highlight;
          this.options.highlight && o && t.setAttribute("wt-highlight", "true")
        }
        var n = this.document.createElement("div");
        n.classList.add("wt-popover"), n.style.borderRadius = this.options.borderRadius + "px", n.style.zIndex = this.options.zIndex + 10, e.placement && n.classList.add(e.placement), this.options.width && ("string" == typeof this.options.width ? n.style.width = this.options.width : 0 < this.options.width && (n.style.width = this.options.width + "px")), e.width && ("string" == typeof e.width ? n.style.width = e.width : 0 < e.width && (n.style.width = e.width + "px"));
        var s = this.document.createElement("div");
        s.classList.add("wt-popover-inner");
        var i = this.document.createElement("div");
        i.classList.add("wt-title"), e.title && s.append(i), e.title && (i.innerText = e.title);
        var l = this.document.createElement("div");
        l.classList.add("wt-content"), s.append(l), l.innerHTML = e.content ? e.content : "";
        var d = null == e.showBtns || "undefined" == e.showBtns || !!e.showBtns;
        if (d) {
          var r = this.document.createElement("button"), a = this.document.createElement("button");
          r.classList.add("wt-btns", "wt-btn-next"), a.classList.add("wt-btns", "wt-btn-back"), r.innerHTML = e.btnNext && e.btnNext.text ? e.btnNext.text : this.stepIndex == this.steps.length - 1 ? "Done" : "Next &#8594;", a.innerHTML = e.btnBack && e.btnBack.text ? e.btnBack.text : 0 == this.stepIndex ? "Close" : "\t&#8592; Back", r.style.backgroundColor = e.btnNext && e.btnNext.backgroundColor ? e.btnNext.backgroundColor : "#7cd1f9", r.style.color = e.btnNext && e.btnNext.textColor ? e.btnNext.textColor : "#fff", a.style.backgroundColor = e.btnBack && e.btnBack.backgroundColor ? e.btnBack.backgroundColor : "#efefef;", a.style.color = e.btnBack && e.btnBack.textColor ? e.btnBack.textColor : "#555", s.append(r), s.append(a)
        }
        var p = this.document.createElement("div");
        if (p.classList.add("wt-arrow"), p.setAttribute("data-popper-arrow", "true"), n.append(p), n.append(s), this.document.body.appendChild(n), t) this.positionPopover(t, n, p, e), this.options.highlight && this.createOverlay(t, e); else {
          if (n.classList.add("wt-slides"), n.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
          }), this.options.highlight) {
            var f = document.createElement("div");
            f.classList.add("wt-overlay", "open"), f.style.zIndex = this.options.zIndex - 10, f.style.position = "fixed", f.style.top = 0, f.style.left = 0, f.style.right = 0, f.style.bottom = 0, this.document.body.appendChild(f)
          }
          p.remove()
        }
        this.options.removeArrow && p.remove()
      }
    }, {
      key: "clear", value: function () {
        var e = this.document.querySelector(".wt-popover"), t = this.document.querySelector(".wt-loader");
        e && e.remove(), t && t.remove(), this.document.querySelectorAll(".wt-overlay").forEach(function (e) {
          e.remove()
        }), this.document.querySelectorAll("*[wt-highlight]").forEach(function (e) {
          e.removeAttribute("wt-highlight")
        })
      }
    }, {
      key: "getWindowOffset", value: function () {
        return {
          height: this.window.innerHeight - (this.window.innerHeight - this.document.documentElement.clientHeight),
          width: this.window.innerWidth - (this.window.innerWidth - this.document.documentElement.clientWidth)
        }
      }
    }, {
      key: "getOffset", value: function (e) {
        for (var t = 0, o = 0, n = e.getBoundingClientRect(); e && !isNaN(e.offsetLeft) && !isNaN(e.offsetTop);) t += e.offsetLeft - e.scrollLeft, o += e.offsetTop - e.scrollTop, e = e.offsetParent;
        return o = parseInt(n.y) > parseInt(o) ? n.y : o, t = parseInt(n.x) > parseInt(t) ? n.x : t, {top: o, left: t}
      }
    }, {
      key: "getTranslateXY", value: function (e) {
        var t = window.getComputedStyle(e), o = new DOMMatrixReadOnly(t.transform);
        return {translateX: n(e.offsetWidth * (o.m41 / 100)), translateY: n(e.offsetHeight * (o.m42 / 100))}
      }
    }, {
      key: "getTranslate3D", value: function (e) {
        var t, o, n, s = window.getComputedStyle(e, null).getPropertyValue("-webkit-transform"),
          i = s.match(/matrix(?:(3d)\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))(?:, (-{0,1}\d+)), -{0,1}\d+\)|\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}.+))(?:, (-{0,1}.+))\))/);
        if (!i) return {X: 0, Y: 0, Z: 0};
        if ("3d" == i[1]) {
          var d = i.slice(2, 5), r = l(d, 3);
          return t = r[0], o = r[1], n = r[2], {X: t, Y: o, Z: n}
        }
        i.push(0);
        var a = i.slice(5, 8), p = l(a, 3);
        return t = p[0], o = p[1], n = p[2], {X: t, Y: o, Z: n}
      }
    }, {
      key: "getElementPosition", value: function (e) {
        return {
          top: this.getOffset(e).top + this.getTranslate3D(e).Y - (e.style.transform ? this.getTranslateXY(e).translateY : 0),
          left: this.getOffset(e).left + this.getTranslate3D(e).X - (e.style.transform ? this.getTranslateXY(e).translateX : 0)
        }
      }
    }, {
      key: "positionPopover", value: function (e, t, o, s) {
        var i = s.placement || "auto", l = s.strategy || "absolute";
        t.style.position = l, o.style.position = "absolute";
        var d, r;
        if (d = this.getElementPosition(e).top, r = this.getElementPosition(e).left, "auto" == i || "auto-start" == i || "auto-end" == i) {
          var a = i.replace("auto", "").trim(), p = "";
          d + (t.offsetHeight + this.options.offset) > this.window.innerHeight - 100 && (r < this.window.innerWidth / 3 ? p = 0 < a.length ? a : "-start" : r > this.window.innerWidth - this.window.innerWidth / 3 && (p = 0 < a.length ? a : "-end"), i = "top" + p), r + e.offsetWidth + t.offsetWidth > this.window.innerWidth && (d < this.window.innerHeight / 3 ? p = 0 < a.length ? a : "-start" : d > this.window.innerHeight - this.window.innerHeight / 3 && (p = 0 < a.length ? a : "-start"), i = "left" + p), r < t.offsetWidth && e.offsetWidth + t.offsetWidth < this.window.innerWidth && (d < this.window.innerHeight / 3 ? p = 0 < a.length ? a : "-start" : d > this.window.innerHeight - this.window.innerHeight / 3 && (p = 0 < a.length ? a : "-start"), i = "right" + p), (d < t.offsetHeight + this.options.offset || 100 > d) && (r < this.window.innerWidth / 3 ? p = 0 < a.length ? a : "-start" : r > this.window.innerWidth - this.window.innerWidth / 3 && (p = 0 < a.length ? a : "-end"), i = "bottom" + p), t.classList.add(i)
        }
        "top" == i ? (t.style.top = d - (t.offsetHeight + this.options.offset) + "px", t.style.left = r + (e.offsetWidth / 2 - t.offsetWidth / 2) + "px") : "top-start" == i ? (t.style.top = d - (t.offsetHeight + this.options.offset) + "px", t.style.left = r - this.options.highlightOffset + "px") : "top-end" == i ? (t.style.top = d - (t.offsetHeight + this.options.offset) + "px", t.style.left = r + e.offsetWidth + this.options.highlightOffset - t.offsetWidth + "px") : "bottom" == i ? (t.style.top = d + e.offsetHeight + this.options.offset + "px", t.style.left = r + e.offsetWidth / 2 - t.offsetWidth / 2 + "px") : "bottom-start" == i ? (t.style.top = d + e.offsetHeight + this.options.offset + "px", t.style.left = r - this.options.highlightOffset + "px") : "bottom-end" == i ? (t.style.top = d + e.offsetHeight + this.options.offset + "px", t.style.left = r + e.offsetWidth + this.options.highlightOffset - t.offsetWidth + "px") : "right" == i ? (t.style.top = d + n(t.offsetHeight - e.offsetHeight) / 2 + "px", t.style.left = r + (e.offsetWidth + this.options.offset) + "px") : "right-start" == i ? (t.style.top = d - this.options.highlightOffset + "px", t.style.left = r + (e.offsetWidth + this.options.offset) + "px") : "right-end" == i ? (t.style.top = d + e.offsetHeight - t.offsetHeight + this.options.highlightOffset + "px", t.style.left = r + (e.offsetWidth + this.options.offset) + "px") : "left" == i ? (t.style.top = d + n(t.offsetHeight - e.offsetHeight) / 2 + "px", t.style.left = r - (t.offsetWidth + this.options.offset) + "px") : "left-start" == i ? (t.style.top = d - this.options.highlightOffset + "px", t.style.left = r - (t.offsetWidth + this.options.offset) + "px") : "left-end" == i && (t.style.top = d + e.offsetHeight - t.offsetHeight + this.options.highlightOffset + "px", t.style.left = r - (t.offsetWidth + this.options.offset) + "px"), "fixed" === l ? this.window.scrollTo(0, 0) : t.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
        })
      }
    }, {
      key: "createOverlay", value: function (e) {
        var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : null,
          o = t && t.strategy ? t.strategy : "absolute", n = document.createElement("div");
        n.classList.add("wt-overlay", "open", "overlay1"), n.style.zIndex = this.options.zIndex - 10;
        var s = document.createElement("div");
        s.classList.add("wt-overlay", "open", "overlay2"), s.style.zIndex = this.options.zIndex - 10;
        var i = document.createElement("div");
        i.classList.add("wt-overlay", "open", "overlay3"), i.style.zIndex = this.options.zIndex - 10;
        var l = document.createElement("div");
        l.classList.add("wt-overlay", "open", "overlay4"), l.style.zIndex = this.options.zIndex - 10, this.document.body.appendChild(n), this.document.body.appendChild(s), this.document.body.appendChild(i), this.document.body.appendChild(l);
        var d, r;
        d = this.getElementPosition(e).top, r = this.getElementPosition(e).left;
        var a = this.options.highlightOffset;
        n.style.position = o, n.style.top = 0, n.style.width = r - a + "px", n.style.height = d + e.offsetHeight + a + "px", n.style.left = 0, s.style.position = o, s.style.top = 0, s.style.right = 0, s.style.height = d - a + "px", s.style.left = r - a + "px", i.style.position = o, i.style.top = d - a + "px", i.style.right = 0, i.style.bottom = 0 - (this.document.body.offsetHeight - this.window.innerHeight) + "px", i.style.left = r + e.offsetWidth + a + "px", l.style.position = o, l.style.top = d + e.offsetHeight + a + "px", l.style.width = r + e.offsetWidth + a + "px", l.style.bottom = 0 - (this.document.body.offsetHeight - this.window.innerHeight) + "px", l.style.left = 0
      }
    }]), t
  }();
  return h
}();
